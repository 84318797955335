.rent {
  .rentPeriod {
    .form-group {
      width: 70px !important;
    }
  }

  .failed {
    color: red;
  }
}

* {
  scroll-behavior: smooth;
}

.scrollable-container {
  overflow-y: auto;
  height: 100px;
  scroll-behavior: smooth;
}

.scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.card-border-bottom {
  border-bottom: 1px solid #e2e2e2;
}

/* CustomerCreate.css */

/* 左大右細 */

.left-section {
  padding-right: 20px;
  /* 調整左右內邊距以防止內容靠近邊框 */
  border-right: 1px solid #dee2e6;
  /* 添加右邊框 */
}

.right-section {
  padding-left: 20px;
  background-color: #f7f7f7;
  /* 調整左右內邊距以防止內容靠近邊框 */
}

/* 中大左右細 */

.middle-section {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px; /* 圓角設計 */
}

.small-left-section {
  padding-top: 10px;
  padding-right: 10px;
  border-right: 3px solid #dee2e6;

  border-radius: 6px; /* 左邊圓角 */
}

.small-right-section {
  padding-top: 10px;

  border-left: 3px solid #dee2e6;

  border-radius: 6px; /* 右邊圓角 */
}
