.av-group {
  width: max-content !important;

  .av-prefix {
    height: fit-content !important;
  }
}

.failed {
  color: red;
}