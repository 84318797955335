.loading-container {
  top: 50%;
  width: 100%;
  height: 100%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-over {
  background-color: #f5f5f58f;

}
.drop-up{
  top: auto;
  bottom: 100%;
}

.vertical-center {

  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}