.upload-icon-large {
  opacity: 0.1 !important;
  width: 40%;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.previewImg {
  padding-left: 10%;
  padding-right: 10%;
  width: max-content;
  max-width: 100%;
}
.drop-area {
  border: 2px dashed #e9ecef;
  background-color: white;
  transition:
    background-color 0.3s,
    border-color 0.3s;
  border-radius: 5%;
}

.drop-area.over {
  border-color: lightgreen;
}
