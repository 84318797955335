/* CustomerCreate.css */

/* 左大右細 */

.left-section {
    padding-right: 20px;
    /* 調整左右內邊距以防止內容靠近邊框 */
    border-right: 1px solid #dee2e6;
    /* 添加右邊框 */
}

.right-section {
    padding-left: 20px;
    /* 調整左右內邊距以防止內容靠近邊框 */
}

/* 中大左右細 */


.middle-section {
    padding-left: 20px;
    padding-right: 20px;
    /* 適當調整內邊距以保持中間區域的間距 */
}

.small-left-section {
    padding-right: 10px;
    /* 左邊小區域，內邊距可以根據需要調整 */
    border-right: 1px solid #dee2e6;
    /* 添加右邊框來區分 */
}

.small-right-section {
    padding-left: 10px;
    /* 右邊小區域，內邊距可以根據需要調整 */
    border-left: 1px solid #dee2e6;
    /* 添加左邊框來區分 */
}